<template>
  <div class="m">
    <newTitle txt="Reference Link"></newTitle>

    <div class="box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="item-tit">{{ item.name }}</div>
        <div class="item-neiron">
          <div
            class="item-nr-it"
            v-for="(item2, index2) in item.son_data"
            :key="index2"
          >
            <div class="item-nr-it-img" @click="bannerItemBtnFn(item2.value)">
              <img :src="item2.icon" alt="" />
            </div>
            <div class="item-nr-it-txt">{{ item2.name }}</div>
          </div>
          <!-- <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">Osaka Convention & Tourism Bureau</div>
          </div>
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">KKDAY</div>
          </div>
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">Klook</div>
          </div> -->
        </div>
      </div>
      <!-- <div class="item">
        <div class="item-tit">Reference Link</div>
        <div class="item-neiron">
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">
              TextTextTextTextText TextTextTextTextText TextTextTextTextText
            </div>
          </div>
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">Osaka Convention & Tourism Bureau</div>
          </div>
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">Osaka Convention & Tourism Bureau</div>
          </div>
          <div class="item-nr-it">
            <div class="item-nr-it-img"></div>
            <div class="item-nr-it-txt">Osaka Convention & Tourism Bureau</div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 底部 -->
    <CBottom :lanPam="lanPam"></CBottom>
    <!-- <div class="btmBox">
      <p>Privacy Policy</p>
      - Copyright © Tripellet.com , All rights reserved -
    </div> -->
  </div>
</template>

<script>
import newTitle from "./Component_title.vue";
import { getProductService } from "@/api/hfIndex";
import CBottom from "./Component_bottom.vue";
export default {
  components: { newTitle, CBottom },
  data() {
    return {
      list: [],
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getProductServiceFn(this.id);
  },
  methods: {
    bannerItemBtnFn(e) {
      // window.location.href(你的外部链接地址);
      if (!e) return;
      window.open(e, "_blank");
    },
    async getProductServiceFn(id) {
      let res = await getProductService({ lang: this.lanPam, id });
      console.log(res);
      this.list = res.data.data;
    },
  },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")] || "tc";
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}
.titleBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 4.2667vw 5.3333vw;
  box-shadow: 0 3px 6px #a8a8a845;
  .el-icon-arrow-left {
    font-size: 5.8667vw;
    position: absolute;
    left: 4.2667vw;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .tit_Txt {
    height: 100%;
    font-size: 3.7333vw;
    color: #000;
  }
}

.box {
  padding: 4.2667vw 4.2667vw 0;
  .item {
    margin-bottom: 10.6667vw;
    .item-tit {
      font-weight: 700;
      font-size: 3.7333vw;
      color: #000;
      margin-bottom: 3.7333vw;
    }
    .item-neiron {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-nr-it {
        width: 44.2667vw;
        margin-bottom: 5.3333vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-nr-it-img {
          width: 100%;
          height: 25.3333vw;
          box-shadow: 0 0 1.6vw #00000029;
          border-radius: 1.6vw;
          margin-bottom: 2.1333vw;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .item-nr-it-txt {
          word-break: break-word;
          font-weight: 700;
          font-size: 3.4667vw;
          color: #000;
        }
      }
    }
  }
}

.btmBox {
  margin: 0 auto;
  max-width: 766px;
  text-align: center;
  background-color: #e5e5e5;
  padding: 5.3333vw 0 7.7333vw;
  font-size: 2.6667vw;
  p {
    margin-bottom: 0.5333vw;
  }
}

@media (min-width: 766px) {
  .titleBox {
    padding: 32px 40px;
    .el-icon-arrow-left {
      font-size: 44px;
      left: 32px;
    }
    .tit_Txt {
      font-size: 28px;
    }
  }

  .box {
    padding: 32px 32px 0;
    .item {
      margin-bottom: 80px;
      .item-tit {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 28px;
      }
      .item-neiron {
        .item-nr-it {
          width: 340px;
          margin-bottom: 40px;
          .item-nr-it-img {
            width: 100%;
            height: 190px;
            box-shadow: 0 0 12px #00000029;
            border-radius: 12px;
            margin-bottom: 16px;
          }
          .item-nr-it-txt {
            font-weight: 700;
            font-size: 26px;
          }
        }
      }
    }
  }

  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 30px;
    }
  }
}
</style>
